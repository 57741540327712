import React, { useState } from "react";
import "../Styles/cardStyle.css";

const Card = ({ data, addToCart }) => {
  const [quantity, setQuantity] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [productCategory, productName, productPrice, productUnit, productImage] = data;

  const incrementQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    addToCart(data, newQuantity);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      addToCart(data, newQuantity);
    } else {
      setQuantity(0);
      addToCart(data, 0); // Remove from cart
    }
  };

  const handleAddToCart = () => {
    setQuantity(1);
    addToCart(data, 1);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="card">
        <div className="card-image" style={{alignItems:"center", marginTop:"10px"}} onClick={openModal}>
          <img src={productImage} alt="Pro" style={{cursor: 'pointer'}} />
        </div>
        <div className="card-content">
          <div className="product-name">{productName}</div>
          <div className="pricing">
            <span className="discounted-price">₹{productPrice}</span>
            <span className="price-unit">{productUnit}</span>
          </div>
        </div>
        {quantity > 0 ? (
          <div className="quantity-controls">
            <button className="decrement-button" onClick={decrementQuantity}>
              <strong>-</strong>
            </button>
            <span className="quantity">{quantity}</span>
            <button className="increment-button" onClick={incrementQuantity}>
              <strong>+</strong>
            </button>
          </div>
        ) : (
          <div>
            <button className="add-button" onClick={handleAddToCart}>
              <strong>ADD</strong>
            </button>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}
          onClick={closeModal}
        >
          <img 
            src={productImage} 
            alt="Product"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain'
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </>
  );
};

export default Card;